<div body class="notifications-filter-list">
    <ng-container *ngFor="let item of listDates; let i = index">

        <div *ngIf="item.data.length > 0" class="notifications-filter-list__list-item">
            <lib-expander
                [title]="item.title" width="100%"
                [expanded]="item.open ? item.open : false"
            >
                <div class="notifications-filter-list__header" expander-header>
                    <span class="notifications-filter-list__header--title">{{item.month}}</span>
                    <span class="notifications-filter-list__header--action" (click)="dismissGroup($event, i)">Clear all</span>
                </div>
                <div expander-body class="notifications-filter-list__body">
                    <app-notification-cards *ngFor="let notification of item.data" [notification]="notification"></app-notification-cards>
                </div>
            </lib-expander>
        </div>
    </ng-container>
</div>
<div body class="notifications-filter-list__footer">
    <span class="notifications-filter-list__footer-title">End of notifications.</span>
    <span class="notifications-filter-list__footer-description">Notifications are purged from Arcus after 31 days. Just a bit of house keeping for you!</span>
</div>



