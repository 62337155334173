<div class="app-loading">
  <div class="app-loading">
    <div class="app-loading__arcus">
      <svg width="127" height="113" viewBox="0 0 127 113" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M45.6073 31.1852L59.8347 6.77045C62.3953 2.37581 65.4147 0.932269 68.963 7.35603L123.293 105.7C124.424 107.744 123.662 109.425 121.614 109.425H99.399C89.6607 109.425 77.0971 101.517 72.0726 91.6056L44.992 38.2148C43.9373 36.1328 44.5757 32.9549 45.6073 31.1848V31.1852Z" stroke="#0365B8" stroke-width="5.9443" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M49.628 80.3078L36.9184 55.9299C35.2626 52.7533 34.7578 52.7082 30.8968 59.3012L3.72812 105.7C2.54794 107.715 3.36122 109.426 5.40795 109.426H31.5129C54.4308 109.426 55.3623 91.31 49.6276 80.3078H49.628Z" stroke="#404040" stroke-width="5.9443" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <img style="max-width: 70px; width: 100%; height: auto;" src="../../../../../assets/images/loading.gif">
  </div>
    <div id="unsupported" style="z-index: 999;"></div>
  </div>
</div>