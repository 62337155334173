import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import { createPartSplit } from '../../../orders/store/orders.actions';
import { Store } from '@ngrx/store';
import { DrawerService } from '../../services/drawer.service';
import { NavigationService } from '@autoflow/angular-ux-components';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { UtilService } from '../../../shared/services/util.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges {
  @Input() data?: any;

  invoicedAt: Dayjs;
  invoiceNumberCheck = new UntypedFormControl('', [Validators.pattern('^[a-zA-Z0-9]+$')]);
  maxDate: string = dayjs().format('YYYY-MM-DD');
  minDate: string = dayjs().subtract(90, 'day').format('YYYY-MM-DD');
  remainingQuantityDelayed = false;
  valid = true;
  itemDelayForm: UntypedFormGroup;

  constructor(
    private drawerService: DrawerService,
    private store: Store,
    private navigationService: NavigationService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.itemDelayForm = this.formBuilder.group({
      delayReason: ['', [Validators.required]],
      delayedUntil: [{ value: '', disabled: false }, [Validators.required]]
    });
  }

  ngOnChanges(): void {
    // RESET FIELDS
    if (this.itemDelayForm) {
      this.itemDelayForm.reset();
    }
    this.invoicedAt = undefined;
    this.invoiceNumberCheck.setValue('');
    this.remainingQuantityDelayed = false;
    this.valid = true;
  }

  adjustQuantity(increment?: number, up = false): void {
    if (increment) {
      this.data.quantityShipped = +this.data.quantityShipped + (up ? +increment : -increment);
    }
    this.data.quantityRemaining = this.data.part.quantity - this.data.quantityShipped;
  }

  closeSidebar(): void {
    this.drawerService.toggle(false);
  }

  confirmSplit(): void {
    if (this.itemDelayForm.getRawValue()?.delayedUntil) {
      this.data.part.delayedUntil = dayjs(this.itemDelayForm.getRawValue()?.delayedUntil).toISOString();
    }
    if (this.itemDelayForm.getRawValue()?.delayReason) {
      this.data.part.delayReason = this.itemDelayForm.getRawValue().delayReason;
    }
    this.data.part = this.utilService.getCopyOf(this.data.part);
    this.store.dispatch(
      createPartSplit({
        partSplitData: {
          data: this.data,
          invoiceNumberCheck: this.invoiceNumberCheck?.value,
          invoicedAt: this.invoicedAt?.toJSON(),
          remainingQuantityDelayed: this.remainingQuantityDelayed
        }
      })
    );
    this.remainingQuantityDelayed = false;
    this.setValue('No');
    this.invoicedAt = null;
    this.invoiceNumberCheck.setValue('');
    this.drawerService.toggle(false);
  }

  isAllowedInput(event: KeyboardEvent): boolean {
    return /^(?:\d|ArrowLeft|ArrowRight|Backspace|Tab)$/.test(event.key);
  }

  toggledDatePicker(open: boolean): void {
    this.navigationService.ignoreClicks = open;
  }

  validateInput() {
    if (this.invoiceNumberCheck.hasError('pattern')) {
      this.valid = false;
      return 'Please use alphanumeric characters';
    }
    if (!this.invoiceNumberCheck.hasError('pattern')) {
      this.valid = true;
    }
  }

  delayValid(): boolean {
    if (
      this.data.part.status !== 'Delayed' &&
      this.data.quantityRemaining > 0 &&
      this.data.quantityRemaining < this.data.part.quantity &&
      this.remainingQuantityDelayed === true
    ) {
      if (!this.itemDelayForm.valid) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  remainingQuantityDelay(val) {
    this.remainingQuantityDelayed = val;
    if (val) {
      this.setValue('Yes');
    } else {
      this.setValue('No');
    }
  }

  setValue(value: string): void {
    if (value === 'Yes') {
      this.itemDelayForm.get('delayedUntil').reset({
        value: this.data.delayedAt ? this.data.delayedUntil : '',
        disabled: false
      });
      this.itemDelayForm.get('delayedUntil').setValidators([Validators.required]);
      this.itemDelayForm.get('delayReason').reset({
        value: '',
        disabled: false
      });
      this.itemDelayForm.get('delayReason').setValidators([Validators.required]);
    } else {
      this.itemDelayForm.get('delayedUntil').reset({ value: '', disabled: true });
      this.itemDelayForm.get('delayedUntil').clearValidators();
      this.itemDelayForm.get('delayedUntil').setErrors(null);
      this.itemDelayForm.get('delayReason').reset({ value: '', disabled: true });
      this.itemDelayForm.get('delayReason').clearValidators();
      this.itemDelayForm.get('delayReason').setErrors(null);
    }
    this.itemDelayForm.get('delayedUntil').updateValueAndValidity();
    this.itemDelayForm.get('delayReason').updateValueAndValidity();
  }
}
