import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CsvFileService {
  constructor() { }

  /**
   * @param the file upload event
   * @returns ?
   */
  openMicrocatFile($event: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const file = $event.srcElement.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = (evt) => {
          resolve(reader.result.toString());
        };
        reader.onerror = (_) => {
          console.log('error reading file');
          reject();
        };
      }
    });
  }

  parseMicrocatFile(data: string): MicrocatPart[] {
    const lines = data.split(/\r/).slice(1);
    const parsedCSV = lines.slice(0, -1).map((l) => l.split(','));
    let rowCount = 0;
    return parsedCSV.map((fields) => {
      rowCount++;
      if (fields.length > 1) {
        return new MicrocatPart(
          fields[1],
          fields[3],
          this.parseInteger(fields[4], rowCount, 'Quantity'),
          this.parseDecimal(fields[11], rowCount, 'Unit Price')
        );
      }
    });
  }

  parseInteger(num: string, row: number, fieldName: string): number {
    const int = parseInt(num, 10);
    if (!isNaN(int)) {
      return int;
    }
    throw Error(`Error parsing ${fieldName} at row ${row + 1}`);
  }

  parseDecimal(num: string, row: number, fieldName: string): number {
    const int = parseFloat(num);
    if (!isNaN(int)) {
      return int;
    }
    throw new Error(`Error parsing ${fieldName} at row ${row + 1}`);
  }
}

export class MicrocatPart {
  partNumber: string;
  description: string;
  quantity: number;
  unitPrice: number;

  constructor(partNumber: string, description: string, quantity: number, unitPrice: number) {
    this.partNumber = partNumber;
    this.description = description;
    this.quantity = quantity;
    this.unitPrice = unitPrice;
  }
}
